/* eslint-disable default-case */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import { StudentContext } from "./context/student-context";
import { useStudentContext } from "./hooks/student-context-hook";
import { TeacherContext } from "./context/teacher-context";
import { useTeacherContext } from "./hooks/teacher-context-hook";
import { TuteurContext } from "./context/tuteur-context";
import { useTuteurContext } from "./hooks/tuteur-context-hook";
import { ContractorContext } from "./context/contractor-context";
import { useContractorContext } from "./hooks/contractor-context-hook";
import { GoogleOAuthProvider } from '@react-oauth/google';

// General Containers
import Layout from "./containers/Layout/Layout";
import Login from "./containers/Login/Login";
import AdminPage from "./containers/AdminPage/AdminPage";
import Home from "./containers/Home/Home";
import Planning from "./containers/Planning/Planning";
import Resources from "./containers/Resources/Resources";

// Teacher Containers
import Evaluation from "./containers/Evaluation/Evaluation";
import Invoicing from "./containers/Invoicing/Invoicing";
import StudentActivity from "./containers/StudentActivity/StudentActivity";
import Tutorials from "./containers/Tutorials/Tutorials";
import Contacts from "./containers/Contacts/Contacts";
import GeneralTermsOfUse from "./containers/GeneralTermsOfUse/GeneralTermsOfUse";
import NotFound from "./containers/NotFound/NotFound";

// Student Containers
import ContactDetailsForm from "./containers/ContactDetailsForm/ContactDetailsForm";
import JustifyAbsences from "./containers/StudentJustifyAbsences/StudentJustifyAbsences";
import Careers from "./containers/Careers/Careers";
import CommunicationKit from "./containers/CommunicationKit/CommunicationKit";
import StudiesManagement from "./containers/StudiesManagement/StudiesManagement";
import StudentLife from "./containers/StudentLife/StudentLife";
import Profile from "./containers/Profile/Profile";
import StudentNews from "./containers/StudentNews/StudentNews";
import StudentFAQ from "./containers/StudentFAQ/StudentFAQ";
import DossierFinancier from "./containers/DossierFinancier/DossierFinancier";
import StudentEvalForm from "./containers/StudentEvalForm/StudentEvalForm";
import DigitalResources from "./containers/DigitalResources/DigitalResources";



// Styles
import "./App.scss";


const App = () => {

    // Auth Hook
    const { token, userRole, userType, userSituation, updateUserSituation, login, logout } = useAuth();

    // Student Context
    const {
        studentStatus,
        studentRestricted,
        studentCampusId,
        studentCurrentPeriod,
        studentProfilePhoto,
        studentName,
        studentFinStatus,
        studentLevel,
        studentSchool,
        studentAttestation,
        studentCertificate,
        studentNextYearLevel,
        studentNextYearCampus,
        studentRythme,
        studentOffresRestriction,
        studentNotificationHome,
        setStudentContext,
        unsetStudentContext,
    } = useStudentContext();

    // Teacher Context
    const {
        teacherAccounts,
        teacherSelectedAccount,
        teacherPlaceId,
        teacherCampusId,
        teacherCanInvoice,
        teacherIsFreelancer,
        teacherVcard,
        teacherCurrentAccountYear,
        teacherCampuses,
        teacherMultipleCampuses,
        teacherCurrentMultipleCampuses,
        teacherName,
        switchTeacherAccount,
        setTeacherContext,
        setTeacherInvoiceDetails,
        unsetTeacherContext,
    } = useTeacherContext();

    // Tuteur Context
    const {
        tuteurStatus,
        tuteurRestricted,
        tuteurCampusId,
        tuteurCurrentPeriod,
        tuteurProfilePhoto,
        tuteurName,
        tuteurFinStatus,
        tuteurLevel,
        tuteurSchool,
        tuteurAttestation,
        tuteurCertificate,
        tuteurNextYearLevel,
        tuteurNextYearCampus,
        tuteurRythme,
        setTuteurContext,
        unsetTuteurContext,
    } = useTuteurContext();

    // Contractor Context
    const {
        contractorMainAccount,
        contractorCampus,
        contractorTeachersIds,
        contractorTeachers,
        contractorCurrentAccountYear,
        contractorIds,
        setContractorContext,
        unsetContractorContext,
    } = useContractorContext();

    let routes, appContext;

    if (!token) {
        routes = (
            <Switch>
                <Route path="/" exact component={Login} />
                <Redirect to="/" />
            </Switch>
        );

        appContext = (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <StudentContext.Provider
                    value={{
                        studentStatus,
                        studentRestricted,
                        studentCampusId,
                        studentCurrentPeriod,
                        studentProfilePhoto,
                        studentName,
                        studentFinStatus,
                        studentLevel,
                        studentSchool,
                        studentAttestation,
                        studentCertificate,
                        studentNextYearLevel,
                        studentNextYearCampus,
                        studentRythme,
                        studentOffresRestriction,
                        studentNotificationHome,
                        setStudentContext,
                        unsetStudentContext,
                    }}>
                    <TeacherContext.Provider
                        value={{
                            teacherAccounts,
                            teacherSelectedAccount,
                            teacherPlaceId,
                            teacherCampusId,
                            teacherCanInvoice,
                            teacherIsFreelancer,
                            teacherVcard,
                            teacherCurrentAccountYear,
                            teacherCampuses,
                            teacherMultipleCampuses,
                            teacherName,
                            teacherCurrentMultipleCampuses,
                            switchTeacherAccount,
                            setTeacherContext,
                            setTeacherInvoiceDetails,
                        }}>
                        <ContractorContext.Provider
                            value={{
                                contractorMainAccount,
                                contractorCampus,
                                contractorTeachersIds,
                                contractorTeachers,
                                contractorCurrentAccountYear,
                                contractorIds,
                                setContractorContext,
                            }}>
                            <TuteurContext.Provider
                                value={{
                                    tuteurStatus,
                                    tuteurRestricted,
                                    tuteurCampusId,
                                    tuteurCurrentPeriod,
                                    tuteurProfilePhoto,
                                    tuteurName,
                                    tuteurFinStatus,
                                    tuteurLevel,
                                    tuteurSchool,
                                    tuteurAttestation,
                                    tuteurCertificate,
                                    tuteurNextYearLevel,
                                    tuteurNextYearCampus,
                                    tuteurRythme,
                                    setTuteurContext,
                                    unsetTuteurContext,
                                    }}>
                                <Layout>{routes}</Layout>
                            </TuteurContext.Provider>
                        </ContractorContext.Provider>
                    </TeacherContext.Provider>
                </StudentContext.Provider>
            </GoogleOAuthProvider>
        );
    } else {
        switch (userType) {

            case "student":
                switch (userSituation) {

                    case "justify-absences":
                        routes = (
                            <Switch>
                                <Route path="/justify-absences" exact component={JustifyAbsences} />
                                <Redirect to="/justify-absences" />
                            </Switch>
                        );
                        break;

                    case "first-login":
                        routes = (
                            <Switch>
                                <Route path="/contact-details" exact component={ContactDetailsForm} />
                                <Redirect to="/contact-details" />
                            </Switch>
                        );
                        break;

                    case "standard":
                        routes = (
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/planning" exact component={Planning} />
                                <Route path="/resources" exact component={Resources} />
                                <Route path="/study-management" exact component={StudiesManagement} />
                                <Route path="/careers" exact component={Careers} />
                                <Route path="/student-life" exact component={StudentLife} />
                                <Route path="/communication-kit" exact component={CommunicationKit} />
                                <Route path="/student-news" exact component={StudentNews} />
                                <Route path="/student-faq" exact component={StudentFAQ} />
                                <Route path="/general-terms-of-use" exact component={GeneralTermsOfUse} />
                                <Route path="/profile" exact component={Profile} />
                                <Route path="/dossier-financier" exact component={DossierFinancier} />
                                <Route path="/student-eval-form/:doc_type" exact component={StudentEvalForm} />
                                <Route path="/digital-resources" exact component={DigitalResources} />
                                {userRole === 'admin' && (
                                    <Route path="/fill-email" exact component={AdminPage} />
                                )}
                                <Route path="/404" exact component={NotFound} />
                                <Redirect to="/404" />
                            </Switch>
                        );
                        break;
                }

                appContext = (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <StudentContext.Provider
                            value={{
                                studentStatus,
                                studentRestricted,
                                studentCampusId,
                                studentCurrentPeriod,
                                studentProfilePhoto,
                                studentName,
                                studentFinStatus,
                                studentLevel,
                                studentSchool,
                                studentAttestation,
                                studentCertificate,
                                studentNextYearLevel,
                                studentNextYearCampus,
                                studentRythme,
                                studentOffresRestriction,
                                studentNotificationHome,
                                setStudentContext,
                                unsetStudentContext,
                            }}>
                            <Layout>{routes}</Layout>
                        </StudentContext.Provider>
                    </GoogleOAuthProvider>
                );
                break;

                case "tuteur":
                    routes = (
                        <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/planning" exact component={Planning} />
                                <Route path="/resources" exact component={Resources} />
                                <Route path="/study-management" exact component={StudiesManagement} />
                                <Route path="/careers" exact component={Careers} />
                                <Route path="/student-life" exact component={StudentLife} />
                                <Route path="/communication-kit" exact component={CommunicationKit} />
                                <Route path="/student-news" exact component={StudentNews} />
                                <Route path="/student-faq" exact component={StudentFAQ} />
                                <Route path="/general-terms-of-use" exact component={GeneralTermsOfUse} />
                                <Route path="/profile" exact component={Profile} />
                                <Route path="/dossier-financier" exact component={DossierFinancier} />
                                <Route path="/student-eval-form/:doc_type" exact component={StudentEvalForm} />
                                <Route path="/digital-resources" exact component={DigitalResources} />
                                {userRole === 'admin' && (
                                    <Route path="/fill-email" exact component={AdminPage} />
                                )}
                                <Route path="/404" exact component={NotFound} />
                                <Redirect to="/404" />
                        </Switch>
                    );
    
                    appContext = (
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                            <TuteurContext.Provider
                                value={{
                                    tuteurStatus,
                                    tuteurRestricted,
                                    tuteurCampusId,
                                    tuteurCurrentPeriod,
                                    tuteurProfilePhoto,
                                    tuteurName,
                                    tuteurFinStatus,
                                    tuteurLevel,
                                    tuteurSchool,
                                    tuteurAttestation,
                                    tuteurCertificate,
                                    tuteurNextYearLevel,
                                    tuteurNextYearCampus,
                                    tuteurRythme,
                                    setTuteurContext,
                                    unsetTuteurContext,
                                }}
                            >
                                <Layout>{routes}</Layout>
                            </TuteurContext.Provider>
                        </GoogleOAuthProvider>
                    );
                    break;

            case "teacher":
                routes = (
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/planning" exact component={Planning} />
                        <Route path="/evaluation" exact component={Evaluation} />
                        <Route path="/admin-resources" exact component={Resources} />
                        <Route path="/invoicing" exact component={Invoicing} />
                        <Route path="/student-activity" exact component={StudentActivity} />
                        <Route path="/tutorials" exact component={Tutorials} />
                        <Route path="/contacts" exact component={Contacts} />
                        <Route path="/general-terms-of-use" exact component={GeneralTermsOfUse} />
                        {userRole === 'admin' && (
                                    <Route path="/fill-email" exact component={AdminPage} />
                        )}
                        <Route path="/404" exact component={NotFound} />
                        <Redirect to="/404" />
                    </Switch>
                );

                appContext = (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <TeacherContext.Provider
                            value={{
                                teacherAccounts,
                                teacherSelectedAccount,
                                teacherPlaceId,
                                teacherCampusId,
                                teacherCanInvoice,
                                teacherIsFreelancer,
                                teacherVcard,
                                teacherCurrentAccountYear,
                                teacherCampuses,
                                teacherMultipleCampuses,
                                teacherCurrentMultipleCampuses,
                                teacherName,
                                switchTeacherAccount,
                                setTeacherContext,
                                setTeacherInvoiceDetails,
                                unsetTeacherContext,
                            }}
                        >
                            <Layout>{routes}</Layout>
                        </TeacherContext.Provider>
                    </GoogleOAuthProvider>
                );
                break;

            case "admin":
                routes = (
                    <Switch>
                        <Route path="/fill-email" exact component={AdminPage} />
                        <Redirect to="/fill-email" />
                    </Switch>
                );

                appContext = (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <StudentContext.Provider
                            value={{
                                studentStatus,
                                studentRestricted,
                                studentCampusId,
                                studentCurrentPeriod,
                                studentProfilePhoto,
                                studentName,
                                studentFinStatus,
                                studentLevel,
                                studentSchool,
                                studentAttestation,
                                studentCertificate,
                                studentNextYearLevel,
                                studentNextYearCampus,
                                studentRythme,
                                studentOffresRestriction,
                                studentNotificationHome,
                                setStudentContext,
                                unsetStudentContext,
                            }}>
                            <TeacherContext.Provider
                                value={{
                                    teacherAccounts,
                                    teacherSelectedAccount,
                                    teacherPlaceId,
                                    teacherCampusId,
                                    teacherCanInvoice,
                                    teacherIsFreelancer,
                                    teacherVcard,
                                    teacherCurrentAccountYear,
                                    teacherCampuses,
                                    teacherMultipleCampuses,
                                    teacherCurrentMultipleCampuses,
                                    teacherName,
                                    switchTeacherAccount,
                                    setTeacherContext,
                                    setTeacherInvoiceDetails,
                                    unsetTeacherContext,
                                }}>
                                <ContractorContext.Provider
                                    value={{
                                        contractorMainAccount,
                                        contractorCampus,
                                        contractorTeachersIds,
                                        contractorTeachers,
                                        contractorCurrentAccountYear,
                                        contractorIds,
                                        setContractorContext,
                                        unsetContractorContext,
                                    }}>
                                    <TuteurContext.Provider
                                        value={{
                                            tuteurStatus,
                                            tuteurRestricted,
                                            tuteurCampusId,
                                            tuteurCurrentPeriod,
                                            tuteurProfilePhoto,
                                            tuteurName,
                                            tuteurFinStatus,
                                            tuteurLevel,
                                            tuteurSchool,
                                            tuteurAttestation,
                                            tuteurCertificate,
                                            tuteurNextYearLevel,
                                            tuteurNextYearCampus,
                                            tuteurRythme,
                                            setTuteurContext,
                                            unsetTuteurContext,
                                            }}>
                                        <Layout>{routes}</Layout>
                                    </TuteurContext.Provider>
                                </ContractorContext.Provider>
                            </TeacherContext.Provider>
                        </StudentContext.Provider>
                    </GoogleOAuthProvider>
                );
                break;

            case "contractor":
                routes = (
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/invoicing" exact component={Invoicing} />
                        <Route path="/tutorials" exact component={Tutorials} />
                        <Route path="/contacts" exact component={Contacts} />
                        <Route path="/general-terms-of-use" exact component={GeneralTermsOfUse} />
                        {userRole === 'admin' && (
                                    <Route path="/fill-email" exact component={AdminPage} />
                        )}
                        <Route path="/404" exact component={NotFound} />
                        <Redirect to="/404" />
                    </Switch>
                );

                appContext = (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                        <ContractorContext.Provider
                            value={{
                                contractorMainAccount,
                                contractorCampus,
                                contractorTeachersIds,
                                contractorTeachers,
                                contractorCurrentAccountYear,
                                contractorIds,
                                setContractorContext,
                                unsetContractorContext,
                            }}>
                            <Layout>{routes}</Layout>
                        </ContractorContext.Provider>
                    </GoogleOAuthProvider>
                );
                break;
        }
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn: !!token, token, userRole, userType, updateUserSituation, login, logout }}>
            {appContext}
        </AuthContext.Provider>
    );
};

export default App;
