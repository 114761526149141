import { useState, useCallback, useEffect } from "react";
import Cookies from 'js-cookie';

let logoutTimer;

export const useAuth = () => {

    // UseState du AuthContext
    const [token, setToken] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState();
    const [userRole, setUserRole] = useState(false);
    const [userType, setUserType] = useState(false);
    const [userSituation, setUserSituation] = useState(false);
    const [backToken, setBackToken] = useState(false);
    const [intervalId, setIntervalId] = useState(false);

    // Login useCallback pour ne pas rentrer dans un cycle infinit
    const login = useCallback((token, expirationDate, userRole, userType, userSituation, backToken) => {

        if (!token || !expirationDate || !userRole || !userType || !backToken) return;

        setToken(token);
        setUserRole(userRole);
        setUserType(userType);
        setUserSituation(userSituation);

        // Parse backend expiration date
        const parsedTokenExpirationDate = new Date(expirationDate);

        // Set parsed date on state
        setTokenExpirationDate(parsedTokenExpirationDate);

        // Store in sessionStorage
        sessionStorage.setItem(
            "userData",
            JSON.stringify({
                token,
                userRole,
                userType,
                userSituation,
                expiration: parsedTokenExpirationDate.toISOString(),
            })
        );

        // Setting backToken (Jwt provided by the backend) in the cookies.
        Cookies.set('PPITJWT', backToken, { expires: 24, secure: true, sameSite: 'strict' });

    }, []);

    // setTeacherInvoiceDetails sets the user invoice account details for invoicing on the app
    const updateUserSituation = useCallback((situation) => {

        // Set States
        setUserSituation(situation);

        // Fetch sessionStorage
        const storedUserData = JSON.parse(sessionStorage.getItem("userData"));

        // Stock new context data
        sessionStorage.setItem(
            "userData",
            JSON.stringify({
                ...storedUserData,
                userSituation: situation
            })
        );
    }, []);

    // logout remets tout à null et supprime l'objet du sessionStorage
    const logout = useCallback(() => {

        // Fetch sessionStorage
        const storedUserData = JSON.parse(sessionStorage.getItem("userData"));

        setToken(null);
        setUserRole(null);
        setUserType(null);
        setUserSituation(null);
        setTokenExpirationDate(null);
        setIntervalId(null);
        if (storedUserData) clearInterval(storedUserData.int);

        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("studentContext");
        sessionStorage.removeItem("tuteurContext");
        sessionStorage.removeItem("teacherContext");
        sessionStorage.removeItem("contractorContext");

        Cookies.remove('PPITJWT');
        
        
    }, [setToken, setUserRole, setUserType, setTokenExpirationDate]);

    useEffect(() => {
        if (token && tokenExpirationDate) {

            // Clear old interval
            if (intervalId) clearInterval(intervalId);
        
            // Session interval token check
            const checkExpDate = () => {
                if (Math.sign(tokenExpirationDate.getTime() - new Date().getTime()) === -1) logout();
            }
            const tokenIntervalId = setInterval(checkExpDate, 1000 * 60 * 10); // Check each ten minutes for token exp. date
            setIntervalId(tokenIntervalId);

            // Fetch sessionStorage
            const storedUserData = JSON.parse(sessionStorage.getItem("userData"));
            
            // Stock new context data
            sessionStorage.setItem(
                "userData",
                JSON.stringify({
                    ...storedUserData,
                    int: tokenIntervalId,
                })
            );
        }
    }, [token, logout, tokenExpirationDate]);
    
    useEffect(() => {
        // Auto-login to application leaning on sessionStorage
        const storedData = JSON.parse(sessionStorage.getItem("userData"));

        if (
            storedData &&
            storedData.token &&
            storedData.userRole &&
            storedData.userType &&
            storedData.userSituation &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(
                storedData.token,
                new Date(storedData.expiration),
                storedData.userRole,
                storedData.userType,
                storedData.userSituation,
            );
            setIntervalId(storedData.int);
        } else logout();
    }, [login]);

    return { token, userRole, userType, userSituation, updateUserSituation, login, logout };
};
